.wrapper {
    display: flex;
    justify-content: center;
    padding-bottom: 1.2rem;
}

.chart {
    position: absolute;
    right: 0;
    left: -10px;
    bottom: -10px;
    z-index: 0;
    pointer-events: none;
}

.grid {
    flex: 1;
    // align-self: flex-end;
    display: grid;
    gap: 0.3rem;
}
