@import '../../scss/colors';
@import '../../scss/vars.scss';
@import '../../scss/mixins';

.wrapper {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // padding: 1rem 10px 0;
    //border: 1px solid $buttonBorderColor;
    border-radius: $buttonBorderRadius;
    font-size: $buttonFontSize;
    width: $buttonWidth;
    // max-width: $buttonMaxWidth;
    height: $buttonHeight;
    // max-height: $buttonMaxHeight;
    // min-height: $buttonMaxHeight * 0.9;
    margin: $buttonMargin;
    cursor: pointer;
    user-select: none;
    @include box-shadow(light);
}

.default {
    background-color: $buttonBgColor;
    color: $buttonColor;
}

.primary {
    background-color: $buttonPrimaryBgColor;
    color: $buttonPrimaryColor;

    .icon {
        fill: #fff;
    }
}

.secondary {
    background-color: $buttonSecondaryBgColor;
    color: $buttonSecondaryColor;

    .icon {
        fill: #fff;
    }
}

.disabled {
    background-color: $buttonDisabledBgColor;
    color: $buttonDisabledColor;
}

.title {
    //text-transform: uppercase;
    padding-top: 0.3rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 135%;
    text-align: center;
}

.icon {
    // fill: grey(400);
    fill: grey(800);
    margin: 0.1rem 0 0;
    width: $buttonIconSize;
    height: $buttonIconSize;
}
