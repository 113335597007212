//@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&subset=latin-ext');
//@import url('https://fonts.googleapis.com/css?family=Open+Sans');

// @import url('https://fonts.googleapis.com/css?family=Open+Sans|Roboto:300,400,500');
@import url('https://fonts.googleapis.com/css?family=Lato|Roboto:300,400,500');

@import 'vars';
@import 'colors';
//@import "./themes/dark";
// @import './themes/light/index';

:global {
    @import './btn';
}

html {
    height: 100%;
}

body {
    min-height: 100vh;
    width: 100vw;
    max-width: 100%;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    background: $bgColor;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: $textColor;

    // no select for touch devices
    //@media (hover: hover) {
    user-select: none;
    //}
}

html,
body {
    // font-size: calc(0.6em + 1vw);
    // font-size: 16px;
    font-size: 4vw;

    @media (-webkit-min-device-pixel-ratio: 2) {
        font-size: 3.8vw;
    }

    @media (-webkit-min-device-pixel-ratio: 3) {
        font-size: 4vw;
    }

    @media only screen and (min-width: 460px) {
        font-size: 19px;
    }

    overscroll-behavior: none;

    // @media (min-width: 400px) {
    //     font-size: 20px;
    // }

    // @media screen and (min-width: 50em) {
    //     font-size: 2vw;
    // }
    // @media (min-width: 768px) {
    //     font-size: 16px;
    // }
}

#root {
    min-height: 100%;
}

* {
    box-sizing: border-box;
    // user-select: none;
}

*:focus {
    outline: none;
}

h1,
h2,
h3,
h4 {
    font-weight: 300;
}

a {
    text-decoration: none;

    :hover {
        text-decoration: none;
    }
}

.space {
    flex: 1 1 auto;
}

.oneLine {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

:global {
    hr {
        border: none;
        border-bottom: 1px solid grey(200);
    }

    body.body-no-scroll {
        overflow: hidden;
    }

    .backdrop {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        backdrop-filter: blur(8px) brightness(75%) contrast(80%);
        z-index: 2;
    }

    #navbarWrapper {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 100;
    }
}

:global {
    .pulse-green {
        background: #41992a;
        box-shadow: 0 0 0 rgba(24, 122, 24, 0.4);
        animation: pulse-green 2s infinite;
    }

    .pulse-red {
        background: #cc796b;
        box-shadow: 0 0 0 rgba(200, 56, 48, 0.4);
        animation: pulse-red 2s infinite;
    }
}

:root {
    // --test: pink;
    // background-color: var(--test);

    // read variable
    // getComputedStyle(document.documentElement).getPropertyValue('--test');

    // set variable
    // document.documentElement.style.setProperty('--test', 'red');
}
