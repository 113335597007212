@import '../../../scss/colors.scss';

$borderStyle: 1px solid grey(200);

li.wrapper {
    display: grid;
    grid-template-columns: 2rem auto 2rem;
    gap: 0.3rem;
    color: grey(800);
    margin: 0;
    padding: 1.2rem 1.1rem 0.8rem;
    list-style: none;
    border-bottom: $borderStyle;
    font-size: 1rem;
    align-items: center;
    background-color: $bgColor;

    &:first-child {
        border-top: $borderStyle;
    }

    &:active {
        background-color: grey(100);
    }

    svg {
        fill: grey(900);
        width: 20px;
        height: 20px;
    }
}
