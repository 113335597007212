$handleBorder: 1px;
$handleSize: 60px;

.colorWheel {
    //max-width: 300px;
    //margin: 0 20%;
    position: relative;

    &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }
}

.colorWheelContent {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid #fff;
    border-radius: 50%;
}

.canvas {
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    touch-action: none;
}

.handle {
    position: absolute;
    border-radius: 50%;
    background-color: rgba(255,255,255,0.4);
    border: 2px solid rgba(0,0,0,0.3);
    width: $handleSize;
    height: $handleSize;
    pointer-events: none;
}
//.draggableWrapper {
//    border-radius: 50%;
//    position: absolute;
//    top: 0;
//    left: 0;
//    width: 100%;
//    height: 100%;
//}
//
//.draggableHandle {
//    //color: #800000;
//    position: relative;
//    background-color: rgba(255,255,255,0.5);
//    width: $handleSize;
//    height: $handleSize;
//    //border-radius: 50%;
//    box-shadow: inset 0 0 5px 0 rgba(128,128,128,0.4);
//    //transform-origin: bottom left;
//}
