.heading1 {
    text-align: center;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 300;
    margin-bottom: 0.25rem;
}

.heading2 {
    width: 100%;
    flex-shrink: 0;
    text-align: center;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 400;
    margin: 1.5rem 0 0.6em;
}

.heading3 {
    width: 100%;
    flex-shrink: 0;
    text-align: center;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 300;
}
