@import '../../scss/vars';
@import '../../scss/colors.scss';

.bodyWrapper {
    // padding: $sideGap;
    padding-bottom: calc(70px + env(safe-area-inset-right, 70px));
    overflow: hidden;
}

// .backgroundMode {
//     // filter: grayscale(60%) blur(0px) contrast(50%) brightness(130%);
//     position: fixed;
//     // top: 0;
//     height: $navBarTotalHeight;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     backdrop-filter: grayscale(60%) brightness(110%) contrast(60%);
//     z-index: 1000;
//     pointer-events: none;
// }

.backgroundMode {
    &::after {
        content: 'Background mode';
        position: fixed;
        top: 0;
        right: 0;
        z-index: 10000;
        background-color: grey(500);
        color: grey(50);
        display: block;
        padding: 0.4rem;
    }
}
