.wrapper {
    position: relative;
}

.settingsButton {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    background: white;
    padding: 3px;
    border: 1px solid #3681d8;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    box-sizing: content-box;
}

.settingsIcon {
    display: block;
    stroke: #3681d8;
}
