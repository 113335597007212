@import '../../scss/colors.scss';
@import '../../scss/vars.scss';

$buttonSize: 2rem;

.placeHolder {
    position: absolute;
    top: -$sideGap;
    left: $sideGap;
    right: $sideGap;
}

.wrapper {
    position: absolute;
    top: -$sideGap;
    left: $sideGap;
    right: $sideGap;
    background-color: #fff;
    background: linear-gradient(180deg, darken(#fff, 4%) 0%, #fff 30%);
    border-radius: 6px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    justify-content: space-around;
    z-index: 100;
    max-width: 30rem;
    margin: 0 auto;

    &.sticky {
        position: fixed;
        top: env(safe-area-inset-top);
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}

.inner {
    display: flex;
    margin: 0.5rem 1rem;
    justify-content: space-around;
}

.button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 0; // this keeps all buttons same size

    .iconWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: $buttonSize;
        height: $buttonSize;
        transform: translateY(0);
        transition: all 300ms;

        svg {
            width: $buttonSize / 2;
            height: $buttonSize / 2;
        }

        .sticky & {
            transform: scale(1.2);
            fill: grey(700);
        }
    }

    .title {
        margin-top: 2px;
        font-size: 0.6rem;
        white-space: nowrap;
        max-height: 10px;
        transition: all 300ms;

        .sticky & {
            overflow: hidden;
            max-height: 0;
            opacity: 0;
        }
    }

    &.active {
        .iconWrapper {
            background-color: $buttonPrimaryBgColor;
            color: #fff;

            svg {
                fill: #fff;
                z-index: 1;
            }
        }

        .title {
            color: primary(800);
        }
    }
}
