@import '../../scss/vars';
@import '../../scss/colors';

$initialHeight: 40%;

.holder {
    position: fixed;
    top: 0;
    right: 0;
    // bottom: $navBarHeight;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    z-index: 100;
}

.topSpace {
    min-height: 100% - $initialHeight;
}

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: $initialHeight;
}

.visibleWrapper {
    position: relative;
    background-color: $navBarBgColor;
    border-top-left-radius: $sideGap;
    border-top-right-radius: $sideGap;
    padding-bottom: env(safe-area-inset-bottom);
}
