@import url(https://fonts.googleapis.com/css?family=Lato|Roboto:300,400,500);
.Login_formWrapper__1vgeh{position:fixed;top:0;right:0;bottom:0;left:0;padding:1.2rem;background-color:#252429;display:flex;flex-direction:column;justify-content:center;align-items:stretch;color:#fff;width:100%;max-width:500px}.Login_largeIcon__30cEV{margin:1rem auto 1.5rem;width:6rem;height:6rem;fill:#fff}.Login_message__3Ym2w{display:block;margin-top:30px;text-transform:uppercase}.Login_errorMessage__2skOt{font-size:1.2rem;text-align:center;margin:2rem 1rem;color:var(--color-fail-100)}.Login_form__121A0{text-align:left}.Login_label__1gR8b{display:block;color:#656476;font-size:.8em;font-weight:bold;padding:0;margin:10px 0 5px 5px}.Login_input__GlNPR{width:100%;font-size:1rem;border:none;border-radius:4px;padding:14px 20px;margin:0 0 15px 0;background-color:#2f3039;color:#b3b2c1}.Login_submitButton__JJHhG{border:none;border-radius:4px;background-color:#3d64ff;font-size:1.1em;color:#fff;width:100%;padding:15px;margin:20px 0 0}.Login_submitButton__JJHhG:active{background-color:#5d7dff}
.NavBarExtra_wrapper__2qW3i{display:flex;flex:0 0 100%}.NavBarExtra_wrapper__2qW3i.NavBarExtra_hasBackground__2cRJ2{background-color:var(--bgColor)}.NavBarExtra_wrapper__2qW3i.NavBarExtra_hasBorder__3dreg{border-top:1px solid #b8b8b8;border-bottom:1px solid #b8b8b8}
.VolumeBar_wrapper__348XT{word-wrap:break-word;overflow:hidden}.VolumeBar_swipeArea__91vpr{position:absolute;top:0;right:0;bottom:0;left:0}.VolumeBar_barWrapper__1JEaK{opacity:.8;transition:opacity 500ms}.VolumeBar_barWrapper__1JEaK.VolumeBar_isVisible__3ILy9{opacity:1}.VolumeBar_bar__1oLfh{height:2px;background-color:var(--color-primary-800);box-sizing:border-box;transition:width 500ms,height 400ms}.VolumeBar_bar__1oLfh.VolumeBar_inProcess__3ek8S{height:4px;transition:none}
.NavBarSwipeArea_wrapper__3SqG9{position:absolute;right:0;bottom:0;left:0;height:5rem}.NavBarSwipeArea_volumeBar__1K6SU{position:absolute;right:0;bottom:0;left:0}
.navBar_navBar__t7lpT{height:5rem;padding-bottom:env(safe-area-inset-bottom);box-sizing:content-box;display:flex;align-items:stretch;flex-wrap:nowrap;border-bottom:1px solid #f1f1f1;background-color:var(--navBarBgColor)}.navBar_navBarItem__3C3dh{color:var(--textColor);flex:1 1 100%;height:100%;display:flex;flex-direction:column;text-align:center;align-items:center;justify-content:center}.navBar_navBarItemActive__2tSJ1{color:var(--color-primary-600)}.navBar_navBarItemActive__2tSJ1 .navBar_icon__2xcLK{fill:var(--color-primary-600)}.navBar_icon__2xcLK{fill:#525252;width:1.5rem;height:1.5rem}.navBar_navBarItemTitle__2i-SW{font-size:.725rem;text-overflow:ellipsis;overflow:hidden;white-space:nowrap;text-transform:uppercase;padding-top:.4rem}
.DragConfirm_overlay__2J7lh{position:fixed;top:0;right:0;bottom:0;left:0;background-color:#fff;z-index:200}.DragConfirm_wrapper__2nf89{position:absolute;left:10%;right:10%;bottom:6rem;display:flex;flex:0 1 auto;flex-direction:column;justify-content:flex-end;background-color:#fff}.DragConfirm_title__1yu8K{font-size:1.2em;text-align:center}.DragConfirm_handleWrapper__3ZW-u{position:relative;background-color:#f7f7f7;padding:1px;border-radius:25px;box-sizing:border-box;box-shadow:inset 0 0 8px 0 rgba(128,128,128,.2)}.DragConfirm_hint__2PTIj{text-transform:uppercase;color:#9e9e9e;font-size:.8em;position:absolute;left:50%;text-align:center;top:50%;transform:translate(-50%, -50%)}.DragConfirm_handle__1zRTG{display:flex;background-color:var(--color-success-200);align-items:center;justify-content:space-around;width:70px;height:50px;border-radius:25px;margin:4px}.DragConfirm_handle__1zRTG.DragConfirm_handleDragging__394o4{background-color:var(--color-success-400)}.DragConfirm_icon__zM9Mt{color:#fff;width:30px;height:30px}.DragConfirm_cancelBtn__iW6id{margin:30px 0 20px}
.Fade_enter__3EeBf{opacity:0}.Fade_enterActive__3ulH6{opacity:1;transition:all 400ms}.Fade_exit__1mGDz{opacity:1}.Fade_exitActive__3rN0D{opacity:0;transition:all 400ms}
.FadeSlow_enter__2CSrw{opacity:0;transform:scale(0.9)}.FadeSlow_enterActive__2b2p5{opacity:1;transform:translateX(0);transition:all 200ms}.FadeSlow_exit__ajA6q{opacity:1}.FadeSlow_exitActive__KYEKD{opacity:0;transform:scale(0.9);transition:all 200ms}
.SlideDown40vh_enter__BDoTX{opacity:.4;transform:translateY(40vh)}.SlideDown40vh_enterActive__1ff_V{opacity:1;transform:translateY(0);transition:all 600ms}.SlideDown40vh_exit__1vNrj{opacity:1;transform:translateY(0)}.SlideDown40vh_exitActive__je9m3{opacity:0;transform:translateY(40vh);transition:transform 600ms,opacity 1200ms}
.button_wrapper__pDqWm{display:flex;flex:0 0 auto;flex-direction:column;justify-content:center;align-items:center;border-radius:1rem;font-size:.65rem;width:4.1rem;height:4.1rem;margin:.3rem .2rem;cursor:pointer;-webkit-user-select:none;user-select:none;box-shadow:2px 2px 6px -4px rgba(0,0,0,.2)}.button_default__1UkW1{background-color:var(--buttonBgColor);color:#858585}.button_primary__3gdax{background-color:var(--color-primary-400);color:var(--color-primary-50)}.button_primary__3gdax .button_icon__2_bDs{fill:#fff}.button_secondary__J2SHE{background-color:var(--color-accent-400);color:var(--color-accent-50)}.button_secondary__J2SHE .button_icon__2_bDs{fill:#fff}.button_disabled__3wfGP{background-color:#f7f7f7;color:#9e9e9e}.button_title__1B_qY{padding-top:.3rem;text-overflow:ellipsis;overflow:hidden;white-space:nowrap;width:135%;text-align:center}.button_icon__2_bDs{fill:#525252;margin:.1rem 0 0;width:1.6rem;height:1.6rem}
.ActionMenu_holder__2vZ2a{position:fixed;top:0;right:0;bottom:0;left:0;overflow-y:auto;z-index:100}.ActionMenu_topSpace__2s445{min-height:60%}.ActionMenu_wrapper__3YC4-{display:flex;flex-direction:column;justify-content:flex-end;min-height:40%}.ActionMenu_visibleWrapper__3stmd{position:relative;background-color:var(--navBarBgColor);border-top-left-radius:1.2rem;border-top-right-radius:1.2rem;padding-bottom:env(safe-area-inset-bottom)}
.IconButton_wrapper__Gq1g8{display:flex;align-items:center;padding:4px}
.ActionMenuHeader_wrapper__vMoy1{display:grid;grid-template-columns:2rem auto 2rem;grid-gap:.3rem;gap:.3rem;color:#858585;padding:1rem 1.1rem .6rem;list-style:none;font-size:1rem;align-items:center;background-color:var(--bgColor);border-top-left-radius:1.2rem;border-top-right-radius:1.2rem}.ActionMenuHeader_wrapper__vMoy1>:last-child{justify-self:flex-end;align-items:flex-start}.ActionMenuHeader_wrapper__vMoy1 svg{fill:#525252;width:20px;height:20px}
.ActionMenuList_wrapper__24eV4{padding-inline-start:0;margin:0}
li.ActionMenuListItem_wrapper__2hHmA{display:grid;grid-template-columns:2rem auto 2rem;grid-gap:.3rem;gap:.3rem;color:#525252;margin:0;padding:1.2rem 1.1rem .8rem;list-style:none;border-bottom:1px solid #d1d1d1;font-size:1rem;align-items:center;background-color:var(--bgColor)}li.ActionMenuListItem_wrapper__2hHmA:first-child{border-top:1px solid #d1d1d1}li.ActionMenuListItem_wrapper__2hHmA:active{background-color:#f7f7f7}li.ActionMenuListItem_wrapper__2hHmA svg{fill:#383838;width:20px;height:20px}
.LedButton_wrapper__24cpW{position:relative}.LedButton_settingsButton__21SA5{position:absolute;top:0;right:0;cursor:pointer;background:#fff;padding:3px;border:1px solid #3681d8;border-radius:50%;width:20px;height:20px;box-sizing:content-box}.LedButton_settingsIcon__2XwFO{display:block;stroke:#3681d8}
.LedModal_overlay__1TOmW{position:fixed;top:0;right:0;bottom:0;left:0;z-index:101;background-color:var(--bgColor);display:flex;align-items:center;justify-content:space-around}.LedModal_closeButton__3Wqoz{position:absolute;top:20px;right:20px}.LedModal_wrapper__PYMYS{background-color:var(--bgColor);width:100vw;text-align:center;padding:20px}.LedModal_slider__zdjL1{-webkit-appearance:none;width:100%;height:15px;border-radius:5px;background:#ccc;outline:none;opacity:.7;transition:opacity .2s}.LedModal_cct__3Cxam{background:linear-gradient(90deg, rgb(40, 171, 225) 0%, rgb(243, 242, 247) 50%, rgb(255, 223, 54) 75%, rgb(248, 147, 31) 100%)}.LedModal_slider__zdjL1::-webkit-slider-thumb{-webkit-appearance:none;appearance:none;width:15px;height:25px;border-radius:30%;background:#fff;border:1px solid #333;box-shadow:0px 0px 3px #333;cursor:pointer}.LedModal_slider__zdjL1::-moz-range-thumb{width:25px;height:25px;border-radius:50%;background:#3681d8;cursor:pointer}
.head_wrapper__18cb1{position:relative;padding-top:calc(1.2rem + env(safe-area-inset-top));padding-left:calc(1.2rem + env(safe-area-inset-left, 1.2rem));padding-right:calc(1.2rem * 1.5 + env(safe-area-inset-right, 1.2rem));padding-bottom:2.4rem;color:#858585;fill:#858585;min-height:460px;display:grid;grid-gap:.6rem;gap:.6rem;grid-template-columns:1fr;grid-template-rows:max-content auto;background-size:cover;filter:var(--themeFilter)}.head_title__1nM-7{position:absolute;bottom:.6rem;left:1.2rem;margin:0}
.heading_heading1__9-dGa{text-align:center;text-transform:uppercase;font-size:1.2rem;font-weight:300;margin-bottom:.25rem}.heading_heading2__1rIjk{width:100%;flex-shrink:0;text-align:center;text-transform:uppercase;font-size:1rem;font-weight:400;margin:1.5rem 0 .6em}.heading_heading3__1vOD9{width:100%;flex-shrink:0;text-align:center;text-transform:uppercase;font-size:.8rem;font-weight:300}
.BlindButton_state__3i8PV{box-shadow:2px 2px 6px -4px rgba(0,0,0,.2);background-color:var(--buttonBgColor);color:#858585;margin:.3rem .2rem;padding:.2rem 0;text-align:center;font-size:.65rem}
.Recuperator_wrapper__365uV{display:flex;justify-content:center}
.Swiper_wrapper__2bLjK{display:flex;flex:0 0 100%;position:relative}.Swiper_clip__2uhwz{width:100%;display:flex;flex:0 0 100%;margin-bottom:-20px;-webkit-clip-path:inset(0 0 20px 0);clip-path:inset(0 0 20px 0)}.Swiper_inner__3bGqY{scroll-snap-type:x mandatory;width:100%;transform:translateZ(0);overflow-x:auto;display:flex;flex-direction:row;scrollbar-width:none;-ms-overflow-style:none;padding-bottom:20px}
.SwiperSlide_wrapper__1KF4E{scroll-snap-align:start;scroll-snap-stop:always;display:flex;flex:0 0 100%;justify-content:center;align-items:center;position:relative;transition:all 100ms}.SwiperSlide_wrapper__1KF4E.SwiperSlide_withTitle__3NSFa{display:grid;width:100%;grid-template-rows:1fr;justify-content:stretch}.SwiperSlide_childrenWithTitle__3A8yr{display:flex;flex:0 0 100%;justify-content:center;align-items:center}
.componentBuilder_generalWrapper__fRdf_{display:flex;align-items:center;justify-content:center}.componentBuilder_button__3u7oO{white-space:normal}.componentBuilder_buttonDisabled__3nkl9{opacity:.5;filter:saturate(50%)}.componentBuilder_buttonTitle__3Y4de{position:absolute;line-height:initial;text-transform:uppercase;font-size:.7rem;bottom:10px}.componentBuilder_carouselSlideTitle__3CUuw{margin:0 0 2px 0}
.toolbar_wrapper__cst-_{display:grid;flex:0 0 100%;grid-template-columns:min-content 1fr min-content;padding-bottom:10px;align-items:center;text-align:center;grid-gap:15px;gap:15px}.toolbar_title__3XWGv{font-size:.8rem;font-weight:300;text-transform:uppercase;opacity:.7}.toolbar_iconSettings__2CbRp{fill:#fff}
.SensorDiff_wrapper__2Qrz1{text-shadow:none;line-height:0;width:10px}.SensorDiff_icon__1SPc9{fill:currentColor}.SensorDiff_inc__3DuSL{color:#a55}.SensorDiff_desc___8crx{color:#a8e9ff;transform:rotate(180deg)}
.SensorValue_value__3c7FG{font-size:2rem;font-weight:300;margin-right:2px}.SensorValue_detailsWrapper__CaaP7{display:grid;grid-template-rows:50% 30%;width:15px;align-items:center;grid-gap:1px;gap:1px;line-height:1;font-size:.6rem}
.SensorLine_wrapper__-4ZFF{display:grid;grid-gap:2rem;gap:2rem;grid-template-columns:auto min-content min-content;padding-right:.6rem;color:#525252;align-items:baseline;text-shadow:1px 1px 1px #fff;z-index:2;line-height:1}.SensorLine_zoneName__1QtzS{text-transform:uppercase;font-size:.9rem;flex:1 1 auto;display:flex;justify-content:flex-end;text-align:right}.SensorLine_temperatureValueWrapper__CsDtz,.SensorLine_humidityValueWrapper__1J3UY{display:flex;flex:0 0 auto;justify-content:flex-end}.SensorLine_value__oqLh9{font-size:1.4rem}
.sensors_wrapper__3F0i3{display:flex;justify-content:center;padding-bottom:1.2rem}.sensors_chart__EeTMz{position:absolute;right:0;left:-10px;bottom:-10px;z-index:0;pointer-events:none}.sensors_grid__1oqAc{flex:1 1;display:grid;grid-gap:.3rem;gap:.3rem}
.DebugOneLineProperty_cta__26OCa{margin-left:6px;background-color:var(--color-primary-400);color:#fff;border:none;border-radius:5px;padding:2px 10px 3px}
.DebugEntry_wrapperLi__1s6PC{list-style:none;display:flex;flex-wrap:wrap}.DebugEntry_keyWrapper__1uht9{position:relative;padding:5px 0 5px 20px}.DebugEntry_iconOpenClose__4Jh-q{position:absolute;left:0;top:50%;transform:translate(0, -50%);fill:#2e2e2e}.DebugEntry_keyEl__1dQII{padding-right:10px;font-weight:bold;cursor:pointer}.DebugEntry_closedPropertyWrapper__37WgR{display:flex;flex:1 1 auto;align-items:center;color:#5d778d;cursor:pointer}.DebugEntry_openPropertyWrapper__2Vs4C{padding-inline-start:1rem;flex-direction:column;display:flex;flex:1 1 100%}.DebugEntry_oneLinePropertyWrapper__14iUF{display:flex;align-items:center}

.DebugDevice_ul__3M3g4{margin:0;padding-left:20px}.DebugDevice_uid__3Ioc_{display:flex;align-items:center;font-size:.8rem;font-weight:bold;border-bottom:1px solid #dbdbdb;padding-bottom:6px;padding-left:10px;margin:20px 0 6px 0;cursor:pointer}.DebugDevice_iconUid__2nSqx{fill:#828282;margin-right:6px;position:relative;top:-1px;cursor:pointer}.DebugDevice_valueInRoot__3sH0K:before{content:": "}.DebugDevice_isTruthy__a4E5u{margin-left:10px;color:#b8b8b8;font-size:.8em}
.DebugObjectSize_wrapper__3kOr6{margin:0 10px}
.DebugStates_wrapper__1aJKN{margin:0 -1.2rem;font-size:.8rem;line-height:1rem;overflow:auto;-webkit-user-select:text;user-select:text}.DebugStates_title__3_35j{font-size:1rem;display:flex;flex:0 0 100%;align-items:center;font-weight:bold;margin:1.5rem 0 .3rem .8rem;cursor:pointer}.DebugStates_titleIcon__1FSZC{margin-left:10px}
.Settings_content__3jeoD{padding:.6rem 1.2rem}
.HomeStateSwitch_placeHolder__1WNFr{position:absolute;top:-1.2rem;left:1.2rem;right:1.2rem}.HomeStateSwitch_wrapper__3-2gK{position:absolute;top:-1.2rem;left:1.2rem;right:1.2rem;background-color:#fff;background:linear-gradient(180deg, whitesmoke 0%, #fff 30%);border-radius:6px;box-shadow:0 0 2px rgba(0,0,0,.5);justify-content:space-around;z-index:100;max-width:30rem;margin:0 auto}.HomeStateSwitch_wrapper__3-2gK.HomeStateSwitch_sticky__14rwv{position:fixed;top:env(safe-area-inset-top);border-top-left-radius:0;border-top-right-radius:0}.HomeStateSwitch_inner__142lS{display:flex;margin:.5rem 1rem;justify-content:space-around}.HomeStateSwitch_button__lcQ-L{display:flex;flex-direction:column;justify-content:center;align-items:center;width:0}.HomeStateSwitch_button__lcQ-L .HomeStateSwitch_iconWrapper__1yfvG{display:flex;align-items:center;justify-content:center;border-radius:50%;width:2rem;height:2rem;transform:translateY(0);transition:all 300ms}.HomeStateSwitch_button__lcQ-L .HomeStateSwitch_iconWrapper__1yfvG svg{width:1rem;height:1rem}.HomeStateSwitch_sticky__14rwv .HomeStateSwitch_button__lcQ-L .HomeStateSwitch_iconWrapper__1yfvG{transform:scale(1.2);fill:#6b6b6b}.HomeStateSwitch_button__lcQ-L .HomeStateSwitch_title__1Zfhk{margin-top:2px;font-size:.6rem;white-space:nowrap;max-height:10px;transition:all 300ms}.HomeStateSwitch_sticky__14rwv .HomeStateSwitch_button__lcQ-L .HomeStateSwitch_title__1Zfhk{overflow:hidden;max-height:0;opacity:0}.HomeStateSwitch_button__lcQ-L.HomeStateSwitch_active__3uTU3 .HomeStateSwitch_iconWrapper__1yfvG{background-color:var(--color-primary-400);color:#fff}.HomeStateSwitch_button__lcQ-L.HomeStateSwitch_active__3uTU3 .HomeStateSwitch_iconWrapper__1yfvG svg{fill:#fff;z-index:1}.HomeStateSwitch_button__lcQ-L.HomeStateSwitch_active__3uTU3 .HomeStateSwitch_title__1Zfhk{color:var(--color-primary-800)}
.home_head__iBVo6{min-height:40vh;position:fixed;top:0;right:0;left:0;z-index:0}.home_content__UxbFT{position:relative;background-color:var(--bgColor);color:var(--primary-color-500);padding:1.2rem;padding-bottom:calc(5rem + env(safe-area-inset-bottom));min-height:60vh;box-shadow:0 -5px 20px rgba(0,0,0,.5);z-index:1}.home_componentBuilderWrapper__9OlBk{-webkit-user-select:none;user-select:none;padding-top:25px;padding-left:calc(10px + env(safe-area-inset-left));padding-right:calc(10px + env(safe-area-inset-right))}
.NavBarExtraButton_wrapper__py-60{display:flex;flex-direction:column;justify-content:center;align-items:center;width:4.2rem;height:3.36rem;padding-top:.2rem}.NavBarExtraButton_wrapper__py-60 svg{fill:#9e9e9e}.NavBarExtraButton_wrapper__py-60.NavBarExtraButton_isActive__2QuGr{border-bottom:2px solid var(--color-primary-400)}.NavBarExtraButton_wrapper__py-60.NavBarExtraButton_isActive__2QuGr svg{fill:#525252}.NavBarExtraButton_icon__tzoO5{width:1.2rem;height:1.2rem}.NavBarExtraButton_title__1ukr8{font-size:.6rem;margin-top:.2rem;color:#9e9e9e}.NavBarExtraButton_isActive__2QuGr .NavBarExtraButton_title__1ukr8{color:#525252}
.NavBarExtraButtonWrapper_wrapper__3dQLv{display:flex;flex:0 0 100%;justify-content:space-around}
.TempDial_wrapper__3ximL{display:flex;align-items:center;justify-content:center;border-radius:50%;width:8rem;height:8rem;position:absolute;top:-4rem;background:linear-gradient(150deg, #32b3f0 33%, #1673ab 74%)}.TempDial_upDownCircle__3UWTK{display:flex;flex-direction:column;justify-content:space-between;align-items:center;width:7.36rem;height:7.36rem;padding:.2rem 0;border-radius:50%;background:linear-gradient(0deg, rgb(223, 223, 223) 50%, rgb(250, 250, 250) 51%)}.TempDial_valueCircle__1GPlH{display:flex;justify-content:center;align-items:center;font-weight:100;color:#9e9e9e;position:absolute;font-size:1.4rem;top:50%;left:50%;transform:translate(-50%, -50%);width:3.2rem;height:3.2rem;border-radius:50%;background:#fff;box-shadow:.2rem .2rem .5rem .1rem rgba(128,128,128,.4)}.TempDial_iconUp__1WMGX,.TempDial_iconDown__1IS4a{width:1.2rem;fill:#fff;filter:drop-shadow(0 2px 0 #a7a7a7)}
.ColorPicker_colorWheel__9WYwc{position:relative}.ColorPicker_colorWheel__9WYwc:after{content:"";display:block;padding-bottom:100%}.ColorPicker_colorWheelContent__2-2NY{position:absolute;width:100%;height:100%;border:1px solid #fff;border-radius:50%}.ColorPicker_canvas__3hhYv{border-radius:50%;position:absolute;top:0;left:0;width:100%;height:100%;touch-action:none}.ColorPicker_handle__24kTE{position:absolute;border-radius:50%;background-color:rgba(255,255,255,.4);border:2px solid rgba(0,0,0,.3);width:60px;height:60px;pointer-events:none}
.ColorPickerWindow_overlay__3_eR1{position:fixed;top:0;right:0;bottom:12rem;left:0;z-index:2000;background-color:var(--bgColor);display:flex;align-items:center;justify-content:space-around}.ColorPickerWindow_wrapper__b_5Um{background-color:var(--bgColor);width:100vw;text-align:center}.ColorPickerWindow_colorPicker__1MFxO{max-width:90%;margin:0 auto 40px}
.debug_ul__YxtS5{margin:0;padding:0 0 0 20px}.debug_ul__YxtS5 li{list-style:none}.debug_ul__YxtS5 li a{display:block;text-transform:capitalize;color:#2e2e2e;padding:10px 0}
.layout_bodyWrapper__WpELo{padding-bottom:calc(70px + env(safe-area-inset-right, 70px));overflow:hidden}.layout_backgroundMode__26_Ww::after{content:"Background mode";position:fixed;top:0;right:0;z-index:10000;background-color:#919191;color:#fff;display:block;padding:.4rem}
.btn{border:none;text-transform:uppercase;font-weight:bold;font-size:.85rem;line-height:17px;color:#fff;padding:12px 20px;background-color:var(--color-primary-600);text-align:center;border-radius:4px}.btn-xs{padding:2px 12px;font-size:.7rem}.btn-wide{padding-left:30px;padding-right:30px}.btn-accent{background-color:var(--color-accent-600)}html{height:100%}body{min-height:100vh;width:100vw;max-width:100%;box-sizing:border-box;padding:0;margin:0;background:var(--bgColor);font-family:"Roboto",sans-serif;font-weight:400;color:var(--textColor);-webkit-user-select:none;user-select:none}html,body{font-size:4vw;overscroll-behavior:none}@media(-webkit-min-device-pixel-ratio: 2){html,body{font-size:3.8vw}}@media(-webkit-min-device-pixel-ratio: 3){html,body{font-size:4vw}}@media only screen and (min-width: 460px){html,body{font-size:19px}}#base_root__2pC97{min-height:100%}*{box-sizing:border-box}*:focus{outline:none}h1,h2,h3,h4{font-weight:300}a{text-decoration:none}a :hover{text-decoration:none}.base_space__7zql9{flex:1 1 auto}.base_oneLine__2sdBO{text-overflow:ellipsis;overflow:hidden;white-space:nowrap}hr{border:none;border-bottom:1px solid #d1d1d1}body.body-no-scroll{overflow:hidden}.backdrop{position:fixed;top:0;right:0;bottom:0;left:0;-webkit-backdrop-filter:blur(8px) brightness(75%) contrast(80%);backdrop-filter:blur(8px) brightness(75%) contrast(80%);z-index:2}#navbarWrapper{position:fixed;left:0;right:0;bottom:0;z-index:100}.pulse-green{background:#41992a;box-shadow:0 0 0 rgba(24,122,24,.4);animation:pulse-green 2s infinite}.pulse-red{background:#cc796b;box-shadow:0 0 0 rgba(200,56,48,.4);animation:pulse-red 2s infinite}
