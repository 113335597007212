@import "../../../scss/colors";

.wrapper {
    word-wrap: break-word;
    overflow: hidden;
}

.swipeArea {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.barWrapper {
    opacity: 0.8;
    transition: opacity 500ms;

    &.isVisible {
        opacity: 1;
    }
}

.bar {
    height: 2px;
    background-color: primary(800);
    box-sizing: border-box;
    transition: width 500ms, height 400ms;

    &.inProcess {
        height: 4px;
        transition: none;
    }
}
