$time: 200;

.enter {
    opacity: 0;
    transform: scale(0.9);
}
.enterActive {
    opacity: 1;
    transform: translateX(0);
    transition: all $time * 1ms;
}
.exit {
    opacity: 1;
}
.exitActive {
    opacity: 0;
    transform: scale(0.9);
    transition: all $time * 1ms;
}

:export {
    timeout: $time;
}
