@import '../../scss/vars';
@import '../../scss/colors';

.overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 101;
    background-color: $bgColor;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.closeButton {
    position: absolute;
    top: 20px;
    right: 20px;
}

.wrapper {
    background-color: $bgColor;
    width: 100vw;
    text-align: center;
    padding: 20px;
}

// .settings {
//     position: fixed;
//     border: 1px solid gainsboro;
//     padding: 10px;
//     border-radius: 3px;
//     left: 50%;
//     top: 50%;
//     min-width: 200px;
//     background: white;
//     transform: translate(-50%, -50%);
//     z-index: 1000;
// }

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 15px;
    border-radius: 5px;
    background: #ccc;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
}

.cct {
    background: linear-gradient(
        90deg,
        rgba(40, 171, 225, 1) 0%,
        rgba(243, 242, 247, 1) 50%,
        rgba(255, 223, 54, 1) 75%,
        rgba(248, 147, 31, 1) 100%
    );
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 25px;
    border-radius: 30%;
    background: white;
    border: 1px solid #333;
    box-shadow: 0px 0px 3px #333;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #3681d8;
    cursor: pointer;
}
