@import './mixins';

// MAIN COLORS
$bgColor: var(--bgColor);
$textColor: var(--textColor);
$colorPrimary: var(--colorPrimary);
$colorAccent: var(--colorAccent);
$colorSuccess: var(--colorSuccess);
$colorFail: var(--colorFail);
$textOnPrimaryColor: var(--textOnPrimaryColor);

@function grey($hue) {
    @return colorScale(#9e9e9e, $hue);
}

@function primary($hue) {
    // @return colorScale($colorPrimary, $hue);
    @return var(--color-primary-#{$hue});
}

@function accent($hue) {
    // @return colorScale($colorAccent, $hue);
    @return var(--color-accent-#{$hue});
}

@function success($hue) {
    // @return colorScale($colorSuccess, $hue);
    @return var(--color-success-#{$hue});
}

@function fail($hue) {
    // @return colorScale($colorFail, $hue);
    @return var(--color-fail-#{$hue});
}

// NAVBAR
$navBarBgColor: var(--navBarBgColor);
$navBarItemActiveColor: var(--navBarBgColor);
$navBarItemTitleColor: var(--textColor);
$navBarIconColor: var(--navBarItemTitleColor);

$buttonBgColor: var(--buttonBgColor);
$buttonColor: grey(600);
$buttonIconColor: grey(600);
$buttonBorderColor: grey(100);

$buttonPrimaryBgColor: primary(400);
$buttonPrimaryColor: primary(50);
$buttonPrimaryIconColor: grey(50);
$buttonPrimaryBorderColor: primary(400);

$buttonSecondaryBgColor: accent(400);
$buttonSecondaryColor: accent(50);
$buttonSecondaryIconColor: grey(50);
$buttonSecondaryBorderColor: accent(500);

$buttonDisabledBgColor: grey(100);
$buttonDisabledColor: grey(400);
$buttonDisabledIconColor: grey(200);
$buttonDisabledBorderColor: accent(300);

// Head
@mixin headBackground() {
    background: linear-gradient(135deg, #03cbfc 28%, #3681d8);
}
