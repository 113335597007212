@import '../../../scss/colors.scss';
@import '../../../scss/vars.scss';

.wrapper {
}

.head {
    min-height: 40vh;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 0;
}

.content {
    position: relative; // required to keep element on top
    background-color: $bgColor;
    color: var(--primary-color-500);
    padding: $sideGap;
    padding-bottom: $navBarTotalHeight;
    min-height: 60vh;
    box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.componentBuilderWrapper {
    user-select: none;
    padding-top: 25px;
    padding-left: calc(10px + env(safe-area-inset-left));
    padding-right: calc(10px + env(safe-area-inset-right));
}
