@import '../../scss/vars';
@import '../../scss/colors';

// .wrapper {
//     border-top: 1px solid #f1f1f1;
//     border-top: 10px solid red;
//     transition: padding 300ms;
//     padding-bottom: env(safe-area-inset-bottom);
//     overscroll-behavior: none;
//     overscroll-behavior-block: contain;
//     overflow-y: scroll;

//     &.wrapperExtended {
//         padding-bottom: $navBarHeight;
//         box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
//     }
// }

.navBar {
    height: $navBarHeight;
    padding-bottom: env(safe-area-inset-bottom);
    box-sizing: content-box;
    display: flex;
    align-items: stretch;
    flex-wrap: nowrap;
    border-bottom: 1px solid #f1f1f1;
    background-color: $navBarBgColor;
    // z-index: 1000;
}

.navBarItem {
    color: $navBarItemTitleColor;
    flex: 1 1 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    // border-top: 2px solid transparent;
}

.navBarItemActive {
    // border-top: 2px solid primary(400);
    color: primary(600);

    .icon {
        fill: primary(600);
    }
}

.icon {
    fill: grey(800);
    width: $navBarIconSize;
    height: $navBarIconSize;
}

.navBarItemTitle {
    font-size: $navBarFontSize;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-transform: uppercase;
    padding-top: 0.4rem;
}
