@import '../../scss/vars.scss';
@import '../../scss/colors.scss';

$iconSize: 6rem;

// .overlay {
//     position: fixed;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     background-color: rgba(0, 0, 0, 0.7);
//     z-index: 5000;
//     display: flex;
//     align-items: center;
//     justify-content: center;
// }

.formWrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: $sideGap;
    background-color: #252429;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    color: #fff;
    width: 100%;
    max-width: 500px;
}

.largeIcon {
    margin: 1rem auto 1.5rem;
    width: $iconSize;
    height: $iconSize;
    fill: #fff;
}

.message {
    display: block;
    margin-top: 30px;
    text-transform: uppercase;
}

.errorMessage {
    font-size: 1.2rem;
    text-align: center;
    margin: 2rem 1rem;
    color: fail(100);
}

.form {
    text-align: left;
}

.label {
    display: block;
    color: #656476;
    font-size: 0.8em;
    font-weight: bold;
    padding: 0;
    margin: 10px 0 5px 5px;
}

.input {
    width: 100%;
    // font-size: 14px;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    padding: 14px 20px;
    margin: 0 0 15px 0;
    background-color: #2f3039;
    color: #b3b2c1;
}

.submitButton {
    border: none;
    border-radius: 4px;
    background-color: #3d64ff;
    font-size: 1.1em;
    color: #fff;
    width: 100%;
    padding: 15px;
    margin: 20px 0 0;

    &:active {
        background-color: #5d7dff;
    }
}
