@import './colors';

.btn {
    border: none;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.85rem;
    line-height: 17px;
    color: grey(50);
    padding: 12px 20px;
    background-color: primary(600);
    text-align: center;
    border-radius: 4px;
}

.btn-xs {
    padding: 2px 12px;
    font-size: 0.7rem;
}

.btn-wide {
    padding-left: 30px;
    padding-right: 30px;
}

.btn-accent {
    background-color: accent(600);
}
