$scrollBarHeight: 20px;

.wrapper {
    display: flex;
    flex: 0 0 100%;
    position: relative;
}

.clip {
    width: 100%;
    display: flex;
    flex: 0 0 100%;
    margin-bottom: -$scrollBarHeight;
    // required to hide horizontal scrollbar
    clip-path: inset(0 0 $scrollBarHeight 0);
}

.inner {
    scroll-snap-type: x mandatory;
    width: 100%;
    transform: translateZ(0);
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */

    // required to hide horizontal scrollbar
    padding-bottom: $scrollBarHeight;
}
