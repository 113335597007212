@import '../../../../scss/colors.scss';

.wrapper {
    display: grid;
    gap: 2rem;
    // grid-template-columns: max-content min-content min-content;
    // grid-template-columns: 1fr 15% 10%;
    grid-template-columns: auto min-content min-content;
    padding-right: 0.6rem;
    // justify-content: flex-end;
    color: grey(800);
    align-items: baseline;
    // text-shadow: 1px 1px 4px rgba(18, 78, 140, 0.9);
    text-shadow: 1px 1px 1px #fff;
    z-index: 2;
    line-height: 1;
}

.zoneName {
    text-transform: uppercase;
    font-size: 0.9rem;
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-end;
    // padding-right: 10vw;
    text-align: right;
}

// .valuesWrapper {
//     display: flex;
//     //flex: 0 0 200px;
//     flex: 0 0 40vw;
//     justify-content: space-evenly;
// }

.temperatureValueWrapper {
    display: flex;
    flex: 0 0 auto;
    justify-content: flex-end;
}

.humidityValueWrapper {
    @extend .temperatureValueWrapper;
    // flex: 0 0 40%;
}

.value {
    font-size: 1.4rem;
}
