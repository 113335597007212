.wrapper {
    text-shadow: none;
    line-height: 0;
    width: 10px;
}

.icon {
    fill: currentColor;
}

.inc {
    color: #aa5555;
}

.desc {
    color: #a8e9ff;
    transform: rotate(180deg);
}
