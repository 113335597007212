@import '../../../scss/colors.scss';
@import '../../../scss/vars.scss';

.wrapper {
    display: flex;
    flex: 0 0 100%;

    &.hasBackground {
        background-color: $bgColor;
    }

    &.hasBorder {
        border-top: 1px solid grey(300);
        border-bottom: 1px solid grey(300);
    }
}
