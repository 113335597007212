@import '../../scss/colors.scss';

$tempDialSize: 8rem;
$upDownCircleSize: $tempDialSize * 0.92;
$valueCircleSize: $tempDialSize * 0.4;

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: $tempDialSize;
    height: $tempDialSize;
    position: absolute;
    top: -4rem;
    background: linear-gradient(150deg, #32b3f0 33%, #1673ab 74%);
}

.upDownCircle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: $upDownCircleSize;
    height: $upDownCircleSize;
    padding: 0.2rem 0;
    border-radius: 50%;
    background: linear-gradient(0deg, rgb(223, 223, 223) 50%, rgb(250, 250, 250) 51%);
}

.valueCircle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 100;
    color: grey(400);
    position: absolute;
    font-size: 1.4rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: $valueCircleSize;
    height: $valueCircleSize;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0.2rem 0.2rem 0.5rem 0.1rem rgba(128, 128, 128, 0.4);
}

// .iconUp {
//     fill: grey(300);
// }

// .iconDown {
//     width: 1rem;
// }

.iconUp,
.iconDown {
    width: 1.2rem;
    fill: #fff;
    filter: drop-shadow(0 2px 0 #a7a7a7);
}
