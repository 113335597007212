.wrapperLi {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}

.keyWrapper {
    position: relative;
    padding: 5px 0 5px 20px;
}

.iconOpenClose {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    fill: #2e2e2e;
}

.keyEl {
    padding-right: 10px;
    font-weight: bold;
    cursor: pointer;
}

.closedPropertyWrapper {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    color: #5d778d;
    cursor: pointer;
}

.openPropertyWrapper {
    padding-inline-start: 1rem;
    flex-direction: column;
    display: flex;
    flex: 1 1 100%;
}

.oneLinePropertyWrapper {
    display: flex;
    align-items: center;
}
