@import '../../scss/colors';

.ul {
    // font-family: monospace;
    margin: 0;
    padding-left: 20px;
}

.uid {
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    font-weight: bold;
    border-bottom: 1px solid #dbdbdb;
    padding-bottom: 6px;
    padding-left: 10px;
    margin: 20px 0 6px 0;
    cursor: pointer;
}

.iconUid {
    fill: #828282;
    margin-right: 6px;
    position: relative;
    top: -1px;
    cursor: pointer;
}

.valueInRoot {
    // font-family: monospace;

    &:before {
        content: ': ';
    }
}

.isTruthy {
    margin-left: 10px;
    color: grey(300);
    font-size: 0.8em;
}
