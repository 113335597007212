$time: 600;

.enter {
    opacity: 0.4;
    transform: translateY(40vh);
}
.enterActive {
    opacity: 1;
    transform: translateY(0);
    transition: all $time * 1ms;
}
.exit {
    opacity: 1;
    transform: translateY(0);
}
.exitActive {
    opacity: 0;
    transform: translateY(40vh);
    transition: transform $time * 1ms, opacity $time * 2ms;
}

:export {
    timeout: $time;
}
