@import '../../scss/vars.scss';

.wrapper {
    margin: 0 (-$sideGap);
    font-size: 0.8rem;
    line-height: 1rem;
    // width: calc(100vw - 10px);
    overflow: auto;
    //word-wrap: no-wrap;
    user-select: text;
}

.title {
    font-size: 1rem;
    display: flex;
    flex: 0 0 100%;
    align-items: center;
    font-weight: bold;
    margin: 1.5rem 0 0.3rem 0.8rem;
    cursor: pointer;
}

.titleIcon {
    margin-left: 10px;
}
