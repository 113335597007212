$sideGap: 1.2rem;
$toolbarHeight: 2rem;
$topBarHeight: 2rem;

// NAVBAR
$navBarHeight: 5rem;
$navBarTotalHeight: calc(#{$navBarHeight} + env(safe-area-inset-bottom));
$navBarFontSize: 0.725rem;
$navBarIconSize: 1.5rem;

$mediaPlayerSelectorHeight: 3rem;

// BUTTON
$buttonFontSize: 0.65rem;
$buttonWidth: 4.1rem;
$buttonHeight: $buttonWidth * 1;
$buttonMaxWidth: 90px;
$buttonMaxHeight: 72px;
$buttonBorderRadius: 1rem;
$buttonMargin: 0.3rem 0.2rem;
$buttonIconSize: 1.6rem;
