@import "../../scss/vars";
@import "../../scss/colors";

.overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: $toolbarHeight + 10;
    left: 0;
    z-index: 2000;
    background-color: $bgColor;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.wrapper {
    background-color: $bgColor;
    width: 100vw;
    text-align: center;
}

.colorPicker {
    max-width: 90%;
    margin: 0 auto 40px;
}
