.value {
    font-size: 2rem;
    font-weight: 300;
    margin-right: 2px;
}

.detailsWrapper {
    display: grid;
    grid-template-rows: 50% 30%;
    width: 15px;
    align-items: center;
    gap: 1px;
    line-height: 1;
    font-size: 0.6rem;
}
