.generalWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.button {
    white-space: normal;
}

.buttonDisabled {
    opacity: 0.5;
    filter: saturate(50%);
}

.buttonTitle {
    position: absolute;
    line-height: initial;
    text-transform: uppercase;
    font-size: 0.7rem;
    bottom: 10px;
}

.carouselSlide {
}

.carouselSlideTitle {
    margin: 0 0 2px 0;
}
