@import '../../../scss/vars.scss';

.wrapper {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: $navBarHeight;
}

.volumeBar {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    //border: 2px solid brown;
}
