@import '../../../scss/vars.scss';
@import '../../../scss/colors.scss';

.wrapper {
    display: grid;
    grid-template-columns: 2rem auto 2rem;
    gap: 0.3rem;
    color: grey(600);
    padding: 1rem 1.1rem 0.6rem;
    list-style: none;
    font-size: 1rem;
    align-items: center;
    background-color: $bgColor;
    border-top-left-radius: $sideGap;
    border-top-right-radius: $sideGap;

    // close icon
    > :last-child {
        justify-self: flex-end;
        align-items: flex-start;
    }

    svg {
        fill: grey(800);
        width: 20px;
        height: 20px;
    }
}
