@function colorScale($color, $hue) {
    @return map-get(
        (
            50: lighten($color, 45%),
            100: lighten($color, 35%),
            200: lighten($color, 20%),
            300: lighten($color, 10%),
            400: $color,
            500: darken($color, 5%),
            600: darken($color, 10%),
            700: darken($color, 20%),
            800: darken($color, 30%),
            900: darken($color, 40%),
        ),
        $hue
    );
}

@mixin box-shadow($strength) {
    @if ($strength == light) {
        box-shadow: 2px 2px 6px -4px rgba(0, 0, 0, 0.2);
    } @else if ($strength == medium) {
        box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.1);
    } @else if($strength == heavy) {
        box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
    }
}
