.wrapper {
    scroll-snap-align: start;
    scroll-snap-stop: always;
    display: flex;
    flex: 0 0 100%;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all 100ms;

    &.withTitle {
        display: grid;
        width: 100%;
        grid-template-rows: 1fr;
        justify-content: stretch;
    }

    &.active {
        // opacity: 1;
        // background-color: burlywood;
    }
}

// .heading {
// position: absolute;
// top: 0;
// right: 0;
// left: 0;
// }

.childrenWithTitle {
    display: flex;
    flex: 0 0 100%;
    justify-content: center;
    align-items: center;
}
