.ul {
    margin: 0;
    padding: 0 0 0 20px;

    li {
        list-style: none;

        a {
            display: block;
            text-transform: capitalize;
            color: #2e2e2e;
            padding: 10px 0;
        }
    }
}
