@import "../../scss/colors";
@import "../../scss/mixins";
@import "../../scss/vars";

$handleHeight: 50px;
$handleWidth: $handleHeight * 1.4;
$borderRadius: $handleHeight / 2;
$sidePadding: 10%;

.overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    //background-color: rgba(255, 255, 255, 0.95);
    background-color: #fff;
    z-index: 200;
}

.wrapper {
    position: absolute;
    left: $sidePadding;
    right: $sidePadding;
    bottom: $toolbarHeight * 3;
    display: flex;
    flex: 0 1 auto;
    flex-direction: column;
    justify-content: flex-end;
    background-color: #fff;
}

.title {
    font-size: 1.2em;
    text-align: center;
}

.handleWrapper {
    position: relative;
    //border: 1px solid grey(100);
    background-color: grey(100);
    padding: 1px;
    border-radius: $borderRadius;
    box-sizing: border-box;
    box-shadow: inset 0 0 8px 0 rgba(128,128,128,0.2);
}

.hint {
    text-transform: uppercase;
    color: grey(400);
    font-size: 0.8em;
    position: absolute;
    left: 50%;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
}

.handle {
    display: flex;
    background-color: success(200);
    align-items: center;
    justify-content: space-around;
    width: $handleWidth;
    height: $handleHeight;
    border-radius: $borderRadius;
    margin: 4px;

    &.handleDragging {
        background-color: success(400);
    }
}

.icon {
    color: #fff;
    width: $handleHeight * 0.6;
    height: $handleHeight * 0.6;
}

.cancelBtn {
    margin: 30px 0 20px;
}