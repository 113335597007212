.wrapper {
    display: grid;
    flex: 0 0 100%;
    grid-template-columns: min-content 1fr min-content;
    padding-bottom: 10px;
    align-items: center;
    text-align: center;
    gap: 15px;
}

.title {
    font-size: 0.8rem;
    font-weight: 300;
    text-transform: uppercase;
    opacity: 0.7;
}

.iconSettings {
    fill: #fff;
}
