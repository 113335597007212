@import '../../../../scss/colors.scss';

$buttonWidth: 4.2rem;
$iconSize: 1.2rem;
$color: grey(400);
$activeColor: grey(800);

.wrapper {
    // border: 1px solid blueviolet;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: $buttonWidth;
    height: $buttonWidth * 0.8;
    padding-top: 0.2rem;

    svg {
        fill: $color;
    }

    &.isActive {
        border-bottom: 2px solid primary(400);

        svg {
            fill: $activeColor;
        }
    }
}

.icon {
    width: $iconSize;
    height: $iconSize;
}

.title {
    font-size: 0.6rem;
    margin-top: 0.2rem;
    color: $color;

    .isActive & {
        color: $activeColor;
    }
}
