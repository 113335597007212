@import '../../scss/vars';
@import '../../scss/colors';
@import '../../scss/mixins';

.state {
    @include box-shadow(light);
    background-color: $buttonBgColor;
    color: $buttonColor;
    margin: $buttonMargin;
    padding: 0.2rem 0;
    text-align: center;
    font-size: $buttonFontSize;
}
