@import "../../scss/colors";

.cta {
    margin-left: 6px;
    background-color: primary(400);
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 2px 10px 3px;
}
