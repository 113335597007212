@import '../../scss/vars';
@import '../../scss/colors';

.wrapper {
    position: relative;
    padding-top: calc(#{$sideGap} + env(safe-area-inset-top));
    padding-left: calc(#{$sideGap} + env(safe-area-inset-left, #{$sideGap}));
    padding-right: calc(#{$sideGap} * 1.5 + env(safe-area-inset-right, #{$sideGap}));
    padding-bottom: $sideGap * 2;
    color: grey(600);
    fill: grey(600);
    min-height: 460px;

    display: grid;
    gap: 0.6rem;
    grid-template-columns: 1fr;
    grid-template-rows: max-content auto;

    background-size: cover;

    // @include headBackground();

    filter: var(--themeFilter);
}

.title {
    position: absolute;
    bottom: 0.6rem;
    left: 1.2rem;
    margin: 0;
}
